import React, { useState, useCallback, useRef, useEffect } from "react";
import validate from "validate.js";
import classNames from "classnames";
import styles from "./index.module.scss";
import { Col } from "~/react-components";

function Input({
  name,
  classname,
  label,
  handleChangeError,
  cols,
  handleChange,
  handleBlur = () => {},
  value,
  maximum,
  type,
  error,
  autoFocus,
  constraint,
  constraintEquality,
  isLoading,
  password,
  ...rest
}) {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  useEffect(() => {
    if (autoFocus === true) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
    setIsFilled(!!inputRef.current?.value);
    // eslint-disable-next-line
  }, []);

  const handleInputBlur = useCallback(async () => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);

    if (constraint) {
      const inputValue =
        type === "number"
          ? parseInt(inputRef.current.value)
          : inputRef.current.value;

      if (type !== "password") {
        await validate
          .async({ [name]: inputValue }, { [name]: constraint })
          .then(null, (err) => {
            handleChangeError(err[name][0]);
          });
      } else if (inputRef.current?.value) {
        await validate
          .async({ [name]: inputValue }, { [name]: constraint })
          .then(null, (err) => {
            handleChangeError(err[name][0]);
          });
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleChangeString = useCallback((event) => {
    if (maximum) {
      event.target.value.length - 1 < maximum &&
        handleChange(event.target.value);
    } else {
      handleChange(event.target.value);
    }
    handleChangeError(false);
    // eslint-disable-next-line
  }, []);

  const handleChangeNumber = useCallback(
    (event) => {
      if (Number.isInteger(parseInt(event.target.value))) {
        maximum
          ? event.target.value.length < maximum &&
            handleChange(parseInt(event.target.value))
          : handleChange(parseInt(event.target.value));
      } else {
        handleChange("");
      }
      handleChangeError(false);
    },
    // eslint-disable-next-line
    [handleChange, maximum]
  );

  return (
    <Col cols={cols} className="form-group mb-3 pl-0 pr-0">
    {label !== "General Notes" && label !== "Referral Source" && (
          <div>
            <label htmlFor={name}>{label}</label>
            <input
              ref={inputRef}
              id={name}
              onChange={(event) => {
                switch (type) {
                  case "number":
                    return handleChangeNumber(event);
                  default:
                    return handleChangeString(event);
                }
              }}
              className={classNames(
                "form-control",
                styles.input,
                {
                  [styles["input--isFocused"]]: isFocused === true,
                },
                {
                  [styles["input--isFilled"]]: isFilled === true,
                },
                {
                  [styles["input--isError"]]: !!error,
                }
              )}
              onFocus={handleInputFocus}
              onBlur={() => {
                handleBlur();
                handleInputBlur();
              }}
              value={value}
              type={type}
              {...rest}
            />
          </div>
        )}
        {error && <span>{error}</span>}
    </Col>
  );
}

export default Input;
