import React from "react";
import { Spinner } from "react-bootstrap";
const LoaderSpinner = ({ size = "sm", variant = "white" }) => {
  return (
    <div className="text-center">
      <Spinner animation="border" size={size} variant={variant} />
    </div>
  );
};

export default LoaderSpinner;
