import React, { useState, useCallback, useRef, useEffect } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Col } from "react-bootstrap";
import styles from "./index.module.scss";
import classNames from "classnames";
import StyledContentLoader from "styled-content-loader";
function Input({
  name,
  classname,
  label,
  handleChangeError,
  cols,
  handleChange,
  handleBlur,
  value,
  maximum,
  type,
  error,
  autoFocus,
  constraint,
  constraintEquality,
  disabled,
  isLoading,
  country='CA',
  ...rest
}) {
  const inputRef = useRef(null);
  const [edited, setEdited] = useState(false);
  useEffect(() => {
    if (autoFocus === true) {
      inputRef.current.focus();
    }
    if(!value){
      setEdited(true)
    }
    // eslint-disable-next-line  
  }, []);
  useEffect(() => {
    return () => {};
  }, []);
//const PhoneInputCountrySelect = document.querySelector(".PhoneInputCountrySelect")
  const handleInputBlur = useCallback(async () => {
    if (!isValidPhoneNumber(value)&&edited) {
      handleChangeError("Invalid phone number");
    }else{
      handleChangeError(false);
    }
    // eslint-disable-next-line
  }, [value, inputRef, edited]);
 
  const handleChangeInput = useCallback(
    (value) => {
      handleChange(value+'');
      if (isValidPhoneNumber(value+'')) {
        handleChangeError(false);
      }
      setEdited(true)
    },
  
    // eslint-disable-next-line
    []
  );
  return (
    <Col
      col={cols.col} 
      sm={cols.sm || cols.col}
      md={cols.md || cols.col}
      lg={cols.lg || cols.col}
      lx={cols.lx || cols.col}
      className={"form-group mb-3 p-0"}
    >
      <StyledContentLoader isLoading={isLoading}>
        <label htmlFor={name}>{label}</label>
        <div style={{ height: "30px" }}>
          <PhoneInput
            value={value}
            ref={inputRef}
            id={name}
            defaultCountry="US"
            country="US"
            international={false}
            withCountryCallingCode={false}
    
            onChange={(event) => handleChangeInput(event)}
            className={classNames(
              styles.input,
            
              {
                [styles[`input--isError`]]: !!error,
              },
              {
                [styles[`input--isDisabled`]]: disabled,
              }
            )}
            onBlur={handleInputBlur}
            disabled={disabled}
            {...rest}
          />
        </div>
         {(error) && <span>{error}  </span>}
      </StyledContentLoader>
    </Col>
  );
}

export default Input;
