import React, {  useEffect } from "react";
import styles from "./index.module.scss";

export const Layout = ({ children }) => {
  useEffect(() => {}, []);

  return (
    <div className={"content-wrap"}>
      <div className={styles.container}>{children}</div>
    </div>
  );
};

export default Layout;
