import React, { createContext, useContext, useState, useEffect } from "react";
import io from "socket.io-client";
import { settings } from "~/business/settings";
const SocketIOContext = createContext({});
const SocketIOProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io(settings.REACT_APP_BASE_URL_DEV_WEB_SOKETIO, {
      transports: ["websocket"],
      upgrade: false,
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionAttempts: Infinity,
      forceNew: true,
      auth: {},
    });
    console.log(newSocket)
    setSocket(newSocket);
  }, []);
  return (
    <SocketIOContext.Provider value={{ socket }}>
      {children}
    </SocketIOContext.Provider>
  );
};

function useSocketIO() {
  const context = useContext(SocketIOContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }

  return context;
}

export { SocketIOProvider, useSocketIO };
