import React, { lazy, Suspense } from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";
import { LoadingPage } from "~/react-components";
const UnsubscribeEmails = lazy(() =>
  import("../modules/unsubscribeEmails/pages/unsubscribeList")
);
const Subscription = lazy(() =>
  import("../modules/subscription/pages/Subscription")
);
const SubscriptionUpdateDone = lazy(() =>
  import("../modules/subscription/pages/SubscriptionDone")
);
const SubscriptionAuth = lazy(() =>
  import("../modules/subscription/pages/SubscriptionAuth")
);
const SubscriptionValidation = lazy(() =>
  import("../modules/subscription/pages/SubscriptionValidation")
);
const SubscriptionAuthValidation = lazy(() =>
  import("../modules/subscription/pages/SubscriptionAuthValidation")
);
const EmailView = lazy(() =>
  import("../modules/email/pages/EmailView")
);
const Routes = () => (
  <Switch>
    <Suspense fallback={<LoadingPage />}>
      <Route path="/"  isPrivate={false}  exact component={Subscription} />
      <Route path="/unsubscribe"  isPrivate={false}  exact component={UnsubscribeEmails} />
      <Route path="/subscription"  isPrivate={false}  exact component={Subscription} />
      <Route path="/subscription/done"  isPrivate={false}  exact component={SubscriptionUpdateDone} />
      <Route path="/subscription/validation"  isPrivate={false}  exact component={SubscriptionValidation} />
      <Route path="/subscription/validation/:token"  isPrivate={false}  exact component={SubscriptionValidation} />
      <Route path="/subscription/validation/mobile/:tokenPublic/:code"  isPrivate={false}  exact component={SubscriptionValidation} />
      <Route path="/subscription/auth/token/:token"  isPrivate={false}  exact component={SubscriptionAuth} />
      <Route path="/subscription/auth/validation"  isPrivate={false}  exact component={SubscriptionAuthValidation} />
      <Route path="/subscription/auth/validation/mobile/:token"  isPrivate={false}  exact component={SubscriptionAuthValidation} />
      <Route path="/subscription/auth/done"  isPrivate={false}  exact component={SubscriptionUpdateDone} />
      <Route path="/email/view/:token"   isPrivate={false} isLayout={false}  exact component={EmailView} />
  </Suspense>
  </Switch>
); 

export default Routes;
